exports.components = {
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-death-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/death.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-death-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-judgment-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/judgment.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-judgment-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-justice-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/justice.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-justice-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-privacy-policy-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/privacy-policy.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-privacy-policy-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-strength-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/strength.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-strength-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-temperance-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/temperance.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-temperance-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-aces-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-aces.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-aces-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-chariot-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-chariot.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-chariot-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-devil-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-devil.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-devil-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-eights-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-eights.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-eights-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-emperor-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-emperor.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-emperor-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-empress-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-empress.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-empress-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-fives-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-fives.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-fives-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-fool-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-fool.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-fool-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-fours-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-fours.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-fours-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-hanged-man-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-hanged-man.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-hanged-man-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-hermit-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-hermit.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-hermit-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-hierophant-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-hierophant.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-hierophant-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-high-priestess-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-high-priestess.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-high-priestess-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-kings-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-kings.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-kings-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-knights-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-knights.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-knights-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-lovers-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-lovers.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-lovers-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-magician-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-magician.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-magician-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-moon-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-moon.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-moon-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-nines-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-nines.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-nines-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-pages-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-pages.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-pages-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-queens-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-queens.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-queens-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-sevens-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-sevens.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-sevens-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-sixes-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-sixes.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-sixes-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-star-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-star.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-star-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-sun-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-sun.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-sun-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-tens-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-tens.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-tens-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-threes-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-threes.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-threes-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-tower-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-tower.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-tower-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-twos-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-twos.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-twos-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-wheel-of-fortune-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-wheel-of-fortune.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-wheel-of-fortune-mdx" */),
  "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-world-mdx": () => import("./../../../src/components/PageLayout/PageLayout.js?__contentFilePath=/opt/build/repo/src/pages-source/the-world.mdx" /* webpackChunkName: "component---src-components-page-layout-page-layout-js-content-file-path-src-pages-source-the-world-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-a-few-ways-of-working-with-tarot-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/a-few-ways-of-working-with-tarot.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-a-few-ways-of-working-with-tarot-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-a-little-about-my-approach-to-divination-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/a-little-about-my-approach-to-divination.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-a-little-about-my-approach-to-divination-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-a-weekend-with-newts-nwts-2019-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/a-weekend-with-newts-nwts-2019.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-a-weekend-with-newts-nwts-2019-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-about-this-site-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/about-this-site.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-about-this-site-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-journeys-into-tarot-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/journeys-into-tarot.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-journeys-into-tarot-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-july-discount-on-readings-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/july-discount-on-readings.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-july-discount-on-readings-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2020-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/nwts-2020.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2020-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2020-schedule-is-up-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/nwts-2020-schedule-is-up.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2020-schedule-is-up-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2022-is-next-weekend-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/nwts-2022-is-next-weekend.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2022-is-next-weekend-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2023-reflections-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/nwts-2023-reflections.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2023-reflections-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2024-reflections-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/nwts-2024-reflections.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-nwts-2024-reflections-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-palmistry-meets-tarot-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/palmistry-meets-tarot.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-palmistry-meets-tarot-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-reblog-what-every-newbie-tarot-reader-should-know-about-the-history-and-myths-of-tarot-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/reblog-what-every-newbie-tarot-reader-should-know-about-the-history-and-myths-of-tarot.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-reblog-what-every-newbie-tarot-reader-should-know-about-the-history-and-myths-of-tarot-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-tarot-reading-at-the-world-village-festival-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/tarot-reading-at-the-world-village-festival.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-tarot-reading-at-the-world-village-festival-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-chariot-strength-hermit-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/the-chariot-strength-hermit.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-chariot-strength-hermit-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-emperor-hierophant-lovers-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/the-emperor-hierophant-lovers.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-emperor-hierophant-lovers-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-fool-magician-high-priestess-empress-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/the-fool-magician-high-priestess-empress.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-fool-magician-high-priestess-empress-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-hanged-man-death-temperance-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/the-hanged-man-death-temperance.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-hanged-man-death-temperance-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-minor-arcana-pips-are-up-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/the-minor-arcana-pips-are-up.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-minor-arcana-pips-are-up-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-wheel-of-fortune-justice-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/the-wheel-of-fortune-justice.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-the-wheel-of-fortune-justice-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-waites-second-tarot-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/waites-second-tarot.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-waites-second-tarot-mdx" */),
  "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-working-with-oracles-reaching-beyond-the-veil-technique-mdx": () => import("./../../../src/components/PostLayout/PostLayout.js?__contentFilePath=/opt/build/repo/src/posts/working-with-oracles-reaching-beyond-the-veil-technique.mdx" /* webpackChunkName: "component---src-components-post-layout-post-layout-js-content-file-path-src-posts-working-with-oracles-reaching-beyond-the-veil-technique-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-readings-index-js": () => import("./../../../src/pages/readings/index.js" /* webpackChunkName: "component---src-pages-readings-index-js" */),
  "component---src-pages-readings-my-reading-style-js": () => import("./../../../src/pages/readings/my-reading-style.js" /* webpackChunkName: "component---src-pages-readings-my-reading-style-js" */),
  "component---src-pages-the-cards-js": () => import("./../../../src/pages/the-cards.js" /* webpackChunkName: "component---src-pages-the-cards-js" */)
}

